var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("section", {
    staticClass: "button-group"
  }, [_c("div", {
    staticClass: "date-picker-wrapper"
  }, [_c("a-range-picker", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeKey == 1,
      expression: "activeKey == 1"
    }],
    attrs: {
      "show-time": "",
      size: "large"
    },
    model: {
      value: _vm.date1,
      callback: function callback($$v) {
        _vm.date1 = $$v;
      },
      expression: "date1"
    }
  }), _c("a-range-picker", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeKey == 2,
      expression: "activeKey == 2"
    }],
    attrs: {
      "show-time": "",
      size: "large"
    },
    model: {
      value: _vm.date2,
      callback: function callback($$v) {
        _vm.date2 = $$v;
      },
      expression: "date2"
    }
  })], 1), _c("div", [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["community:btn:search"],
      expression: "['community:btn:search']"
    }],
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleSearch
    }
  }, [_vm._v("查询")]), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["community:btn:reset"],
      expression: "['community:btn:reset']"
    }],
    staticStyle: {
      "margin-left": "20px",
      border: "0",
      background: "#f5f5f5"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.handleReset
    }
  }, [_vm._v("重置")])], 1)]), _c("section", {
    staticClass: "table-wrapper"
  }, [_c("a-tabs", {
    attrs: {
      "default-active-key": _vm.activeKey
    },
    on: {
      change: _vm.onTabChange
    }
  }, [_c("a-tab-pane", {
    key: 1,
    attrs: {
      tab: "机审命中"
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      "data-source": _vm.dataSource,
      columns: _vm.columns,
      loading: _vm.loading,
      rowKey: "id"
    },
    scopedSlots: _vm._u([{
      key: "source",
      fn: function fn(source) {
        return [_vm._v(" " + _vm._s(source == 1 ? "机审命中" : "用户举报") + " ")];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [_vm._v(" " + _vm._s(_vm._f("formatType")(type)) + " ")];
      }
    }, {
      key: "status",
      fn: function fn(status) {
        return [status ? _c("a-button", {
          staticClass: "btn-text",
          attrs: {
            type: "link"
          }
        }, [_vm._v("已通过")]) : _c("span", [_vm._v("未通过")])];
      }
    }, {
      key: "createtime",
      fn: function fn(createtime) {
        return [_vm._v(" " + _vm._s(_vm.$moment(createtime * 1000).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(scope) {
        return [_c("div", [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["community:btn:detail"],
            expression: "['community:btn:detail']"
          }],
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handlePreview(scope);
            }
          }
        }, [_vm._v("查看")]), !scope.status ? _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["community:btn:update"],
            expression: "['community:btn:update']"
          }],
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handlePass(scope.id);
            }
          }
        }, [_vm._v("通过")]) : _vm._e(), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["community:btn:delete"],
            expression: "['community:btn:delete']"
          }],
          staticClass: "btn-del",
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleDel(scope.id, scope.type);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-tab-pane", {
    key: 2,
    attrs: {
      tab: "用户举报",
      "force-render": ""
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      "data-source": _vm.dataSource2,
      columns: _vm.columns2,
      loading: _vm.loading,
      rowKey: "id"
    },
    scopedSlots: _vm._u([{
      key: "source",
      fn: function fn(source) {
        return [_vm._v(" " + _vm._s(source == 1 ? "机审命中" : "用户举报") + " ")];
      }
    }, {
      key: "type",
      fn: function fn(type) {
        return [_vm._v(" " + _vm._s(_vm._f("formatType")(type)) + " ")];
      }
    }, {
      key: "createtime",
      fn: function fn(createtime) {
        return [_vm._v(" " + _vm._s(_vm.$moment(createtime * 1000).format("YYYY-MM-DD HH:mm:ss")) + " ")];
      }
    }, {
      key: "action",
      fn: function fn(scope) {
        return [_c("div", [_c("a-button", {
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handlePreview(scope);
            }
          }
        }, [_vm._v("查看")]), _c("a-button", {
          staticClass: "btn-del",
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              return _vm.handleDel(scope.id, scope.type);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total2,
      page: _vm.page2,
      size: _vm.size2,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page2 = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size2 = $event;
      }
    }
  })], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      closable: false,
      width: "46%",
      title: _vm.dialogTitle
    },
    on: {
      ok: function ok() {
        return _vm.visible = false;
      }
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "modal-body"
  }, [_c("div", {
    staticClass: "moda-body-flex"
  }, [_c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.headimgurl,
      alt: ""
    }
  }), _c("div", {
    staticClass: "content",
    domProps: {
      innerHTML: _vm._s(_vm.htmlStr)
    }
  })]), _vm.activeKey == 2 ? _c("div", [_c("h3", [_vm._v("被举报内容：")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.reportcontent)
    }
  }), _vm.reportlinkurl ? _c("video", {
    attrs: {
      src: _vm.reportlinkurl,
      controls: "",
      controlslist: "nodownload"
    }
  }) : _vm._e()]) : _vm.reportType == 5 ? _c("div", [_c("h3", [_vm._v("关联视频：")]), _vm.reportlinkurl ? _c("video", {
    attrs: {
      src: _vm.reportlinkurl,
      controls: "",
      controlslist: "nodownload"
    }
  }) : _vm._e()]) : _vm._e()]), _c("div", {
    staticClass: "customize-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click() {
        return _vm.visible = false;
      }
    }
  }, [_vm._v("确定")])], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };